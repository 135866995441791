<template>
  <BaseSidebar :transparent="!parcels.length" @input="emit">
    <template #action-before>
      <base-tooltip>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="reset()">
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </template>
        <span>Clear Search and Close</span>
      </base-tooltip>
    </template>
    <template #default>
      <div class="search-container" :style="searchContainerStyle" v-hotkey="keymap">
        <div class="container mt-n2">
          <div class="px-5 pt-3">
            <v-text-field
              @keyup.esc="onClickEsc"
              :append-icon="searchQuery ? 'mdi-close' : ''"
              @click:append="searchQuery = ''"
              ref="search"
              outlined
              background-color="white"
              v-model="searchQuery"
              placeholder="Search for..."
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="list-container">
        <div v-if="parcels.length" class="list">
          <div class="result-stat">
            <strong>{{ `${searchCount} parcel${searchCount > 1 ? 's' : ''} ` }} found</strong>
          </div>
          <div
            v-for="parcel in parcels"
            :key="parcel.id"
            class="list-item"
            @click="onClickParcel(parcel)"
            @mouseenter="hover(parcel, true)"
            @mouseleave="hover(parcel, false)"
          >
            <div class="d-flex justify-space-between">
              <div v-html="parcel.highlight"></div>
              <div v-if="parcel.distance">
                <div class="d-flex align-center">
                  <v-icon size="20">$distance</v-icon>
                  <span>
                    {{ parcel.distance | formatDistance }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="$store.state.parcel.parcelLoading && searchQuery">
          <v-skeleton-loader v-for="i in 5" :key="i" type="list-item-two-line"></v-skeleton-loader>
        </div>
        <div v-else-if="!$store.state.parcel.parcelLoading && searchQuery" class="ma-3">
          <PeepsAlertAbove color="error" dark :outlined="false">
            No matching parcels found
          </PeepsAlertAbove>
        </div>
        <div v-else>
          <v-container>
            <PeepsAlertAbove color="peeps" dark>
              Search for either a parcel number, address, reference, or owner name. Results will
              show here.
            </PeepsAlertAbove>
          </v-container>
        </div>
      </div>
    </template>
  </BaseSidebar>
</template>

<script>
import Vue from 'vue';
import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);

import VueHotkey from 'v-hotkey';
Vue.use(VueHotkey);

import searchParcel from '../../mixins/searchParcel';
import { getStreetAddress } from '../../utils/parcel';
export default {
  mixins: [searchParcel],
  components: {
    PeepsAlertAbove: () => import('../../components/Core/Alerts/PeepsAlertAbove.vue')
  },
  data() {
    return {
      isTouchDevice: false
    };
  },
  computed: {
    keymap() {
      return {
        esc: this.onClickEsc
      };
    },
    parcels() {
      return this.searchedParcels;
    },
    searchContainerStyle() {
      let style = ``;
      if (this.parcels.length) {
        style += `background-color: #fff !important; z-index: 5;`;
      }
      return style;
    }
  },

  mounted() {
    const el = document.querySelector('.v-text-field__details');
    if (el) {
      el.remove();
    }
    setTimeout(() => {
      const s = this.$refs.search;
      if (s) {
        s.focus();
      }
    }, 100);
    this.checkTouchDevice();
  },
  filters: {
    formatDistance(distance) {
      if (!distance) {
        return '';
      }
      const d = Number(distance);
      if (d < 0.2) {
        return Math.round(d * 1760) + ' yd';
      } else {
        return distance + ' mi';
      }
    }
  },
  methods: {
    reset() {
      this.searchQuery = '';
      this.$store.commit('setSearchDialog', false);
    },
    checkTouchDevice() {
      function isTouchDevice() {
        return (
          'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
        );
      }
      this.isTouchDevice = isTouchDevice();
    },
    hover(parcel, state) {
      if (this.isTouchDevice) {
        return;
      }
      this.$bus.$emit('renderHoverParcel', parcel, state);
    },
    emit(value) {
      this.$store.commit('setSearchDialog', value);
    },
    onClickEsc() {
      if (this.searchDialog) {
        this.searchQuery = '';
      }
    },
    onClickParcel(parcel) {
      const view = this.$route.path.split('/');
      this.$bus.$emit('clickParcel', parcel);
      if (view[view.length - 1] === 'map') {
        this.searchDialog = false;
      }
    },
    getStreetAddress
  },
  watch: {
    '$store.state.parcel.searchDialog': {
      handler(value) {
        if (!value) {
          setTimeout(() => {
            this.$bus.$emit('renderSearchResults', this.parcels || []);
          }, 500);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../design/_colors.scss';
.search-container {
  position: sticky;
  top: 0;
}

.v-text-field__details {
  display: none;
}
.v-messages {
  display: none;
}

.list-item {
  padding: 15px;
  padding-top: 0;
  margin-top: 1px;
  border-bottom: 1px solid rgb(241, 235, 235);
  cursor: pointer;
  &:hover {
    background-color: $color-primary-light;
  }
}
.result-stat {
  font-size: 14px;
  color: #9aa0a6;
  line-height: 43px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Google Sans, arial, sans-serif;
  padding-top: 0px;
  padding-bottom: 0;
  margin-left: 1rem;
  margin-bottom: 0;
}
</style>
